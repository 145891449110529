import { PaymentStatuses } from "@/assets/ts/enums/payment/PaymentStatuses";
import { PaymentStatusSeverity } from "@/assets/ts/enums/payment/PaymentStatusSeverity";

export class PaymentStatus {
	constructor(private code: string) {}

	private paymentStatusMapping: { [key: string]: PaymentStatuses } = {
		paid: PaymentStatuses.paid,
		unpaid: PaymentStatuses.unpaid,
	};

	private paymentStatusSeverityMapping: { [key: string]: PaymentStatusSeverity } = {
		paid: PaymentStatusSeverity.paid,
		unpaid: PaymentStatusSeverity.unpaid,
	};

	get statusName() {
		return computed(() => this.paymentStatusMapping[this.code]);
	}

	get statusSeverity() {
		return computed(() => this.paymentStatusSeverityMapping[this.code]);
	}
}
